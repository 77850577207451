<script lang="ts" setup>
import type { PropType } from 'vue'

import Heading from '@/components/Heading.vue'
import IconBase from '@/components/IconBase.vue'
import IconCheckmarkCircle from '@/components/icons/IconCheckmarkCircle.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import SiteHeader from '@/components/SiteHeader.vue'
import SkrImage from '@/components/SkrImage.vue'

const props = defineProps({
  isSealing: Boolean,
  // Apparently, this may be set by an external consumer,
  // so we need to be aware of it
  isSimpleUi: Boolean,
  processedRequests: {
    type: Array as PropType<string[]>,
    default: () => [],
  },
})

const emit = defineEmits(['close'])

const { t, locale } = useI18n()

const exitUrlStore = useExitUrlStore()

const { directSignSession } = storeToRefs(useDirectSignStore())
const { exitDelay } = storeToRefs(exitUrlStore)
const { areExitUrlsApplicable, leaveToExitUrl, initAutoRedirect } = exitUrlStore
const { getPagePreviewUrl } = useDocumentHelpers()
const { downloadDocuments } = useDocumentActions()

const isSingleRequest = ref(props.processedRequests?.length === 1)
const signatureRequestData = ref<SignatureRequestData | null>(null)

const download = async () => {
  if (!signatureRequestData.value) return
  const documentIds = signatureRequestData.value.documents.map(doc => doc.versionId)
  await downloadDocuments(
    signatureRequestData.value.id,
    documentIds,
    signatureRequestData.value.statusOverall === 'SIGNED'
  )
}

const info = computed(() => {
  const numOfRequests = props.processedRequests?.length ?? 0

  if (props.isSealing) {
    return {
      prepend: t('seal.success.header_prepend'),
      title: t('seal.success.title'),
      subtitle: t('seal.success.subtitle', { n: numOfRequests }, numOfRequests),
    }
  }
  return {
    prepend: t('sign.success.header_prepend'),
    title: t('sign.success.title'),
    subtitle: t('sign.success.subtitle', { n: numOfRequests }, numOfRequests),
  }
})

const onClose = async () => {
  exitUrlStore.cancelRedirect()
  await navigateTo({ name: 'index', query: { tab: 'inbox', filter: 'toSign', refresh: 'true' } })
  emit('close')
}

const signerDisplayString = (signature: SignatureData) => {
  if (signature.signerIdentityData && Object.keys(signature.signerIdentityData).length > 0) {
    if (signature.signerIdentityData.firstName || signature.signerIdentityData.lastName) {
      return `${signature.signerIdentityData.firstName ?? ''} ${signature.signerIdentityData.lastName ?? ''}`
    } else if (signature.signerIdentityData.emailAddress) {
      return signature.signerIdentityData.emailAddress
    }
    return ''
  } else if (signature.accountEmail.includes('@')) {
    return signature.accountEmail
  } else return ''
}

const { srRepository, directSrRepository } = useApi()

const updateSignatureRequestData = async () => {
  signatureRequestData.value = directSignSession.value
    ? await directSrRepository.get(props.processedRequests[0])
    : await srRepository.get(props.processedRequests[0])
}

watchEffect(() => {
  if (signatureRequestData.value?.id && isSingleRequest.value) {
    initAutoRedirect(signatureRequestData.value.id)
  }
})

onMounted(() => {
  if (isSingleRequest.value) {
    void updateSignatureRequestData()
  }
})

onBeforeUnmount(() => {
  exitUrlStore.cancelRedirect()
})
</script>

<template>
  <overlay-base :transition="false">
    <template #header>
      <site-header :dismissible="!isSimpleUi" :action="onClose" :overlay="true">
        <template v-if="isSingleRequest">
          <strong>{{ info.prepend }}</strong>
          {{ signatureRequestData?.title ?? '' }}
        </template>
      </site-header>
    </template>
    <v-container class="overlay__body" :class="{ 'fill-height': $vuetify.display.mdAndUp }">
      <div class="sra">
        <template v-if="isSingleRequest && signatureRequestData">
          <heading :center="true" :title="info.title" :subtitle="info.subtitle" />
          <div class="sra__details">
            <div class="sra__picture">
              <skr-image
                class="sra__image"
                :src="getPagePreviewUrl(signatureRequestData.documents[0].versionId, 0, 20)"
              />
            </div>
            <div class="sra__text">
              <div class="sra__row is-document">
                <strong>{{ t('global.signature_request') }}</strong> <br />
                {{ signatureRequestData.title }}
              </div>
              <div
                v-for="(signature, index) in signatureRequestData.signatures"
                :key="`${signature.accountEmail}-${index}`"
                class="sra__row"
              >
                <div class="sra__signer">
                  <template v-if="index < 3">
                    <strong>{{ t('global.numbered_signer', index) }}</strong>
                  </template>
                  <template v-else>
                    <strong>
                      {{ t('global.nth_signer', { n: index + 1 }) }}
                    </strong>
                  </template>
                  <br />
                  {{ signerDisplayString(signature) }}
                </div>
                <div class="sra__signer-check" :class="{ 'has-not-signed': signature.statusCode === 'OPEN' }">
                  <icon-base
                    v-if="signature.statusCode === 'SIGNED'"
                    name="checkmark-circle"
                    height="30"
                    width="30"
                    view-box="0 0 30 30"
                  >
                    <icon-checkmark-circle />
                  </icon-base>
                </div>
              </div>
            </div>
          </div>
          <div class="sra__actions">
            <template v-if="areExitUrlsApplicable([signatureRequestData.id])">
              <v-btn
                v-if="!isSimpleUi"
                color="info"
                size="x-large"
                variant="outlined"
                class="mr-md-2 mb-4 mb-md-0"
                @click="download"
              >
                {{ t('global.download_document') }}
              </v-btn>
              <v-btn color="primary" size="x-large" class="ml-md-2" @click="leaveToExitUrl">
                {{ t('global.next') }}
              </v-btn>
            </template>

            <template v-else>
              <v-btn
                v-if="directSignSession"
                data-cy="viewDocumentButton"
                :href="`/view/${signatureRequestData.id}/${directSignSession.auth.username}?lang=${locale.substring(
                  0,
                  2
                )}`"
                size="x-large"
                variant="outlined"
                color="info"
                class="mr-md-2 mb-4 mb-md-0"
              >
                {{ t('global.view_document') }}
              </v-btn>
              <v-btn
                v-else
                data-cy="viewDocumentButton"
                :href="`/view/${signatureRequestData.id}`"
                size="x-large"
                variant="outlined"
                color="info"
                class="mr-md-2 mb-4 mb-md-0"
              >
                {{ t('global.view_document') }}
              </v-btn>

              <v-btn size="x-large" color="info" class="ml-md-2" @click="download">
                {{ t('global.download_document') }}
              </v-btn>
            </template>
          </div>
          <div
            v-if="areExitUrlsApplicable([signatureRequestData.id])"
            class="text-center"
            :class="{ 'mt-4': $vuetify.display.smAndUp }"
            data-cy="redirect_message"
          >
            {{
              t('signature_request.success.automatic_redirection', {
                n: exitDelay,
              })
            }}
          </div>
        </template>
        <template v-else-if="!isSingleRequest">
          <heading :center="true" :title="info.title" :subtitle="info.subtitle" />
          <div class="sra__actions">
            <v-btn color="primary" size="x-large" @click="onClose">
              {{ t('global.back_to_documents') }}
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="loading">
            <v-progress-circular :size="70" color="text" indeterminate />
          </div>
        </template>
      </div>
    </v-container>
  </overlay-base>
</template>

<style lang="sass">
.overlay__body
  +miw(sm)
    padding-top: 90px
.sra
  +center-content
  padding-top: 1.75rem

  &__check
    color: $c-white

    .fill
      fill: $c-success

  &__invite
    color: $c-white

    .fill
      fill: $c-primary

  &__details
    display: flex
    margin: 82px 0 (15 * $bw)
    +max-width
      flex-direction: column
      align-items: center
      width: 100%
      margin: 0 0 (7 * $bw)

    strong
      color: $c-text-alt

  &__picture
    +skribble-box-shadow
    display: flex
    justify-content: center
    align-items: center
    width: 200px
    height: 270px
    margin: 0 (20 * $bw) 0 (8 * $bw)
    border-radius: 3px
    background-color: $c-white

  &__image
    max-width: 100%
    max-height: 100%

  &__text
    width: 100%
    +min-width
      width: 80 * $bw
    +max-width
      margin-top: 7 * $bw

  &__row
    display: flex
    overflow: hidden
    text-overflow: ellipsis
    padding: (4 * $bw) 0
    border-bottom: 1px solid $c-border
    &.is-document
      display: block

  &__signer
    flex: 1 1 auto
    max-width: calc(100% - 30px)
    overflow: hidden
    text-overflow: ellipsis

    &-check
      flex: 0 0 auto
      position: relative
      top: 7px
      display: block
      width: 30px
      height: 30px
      color: $c-white

      .fill
        fill: $c-success

      &.has-not-signed
        border: 4px solid $c-border
        border-radius: 50%

  &__actions
    display: flex
    +maw(sm)
      width: 100%
      flex-direction: column
      .v-btn
        width: 100%
    +miw(sm)
      align-items: stretch
      margin: 0 (7 * $bw) (7 * $bw)

    .v-size--large
      min-width: 11.875rem !important
      height: 4rem
      font-size: 1.25rem
      letter-spacing: .4px
</style>
